import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import ClientCarousel2 from "@/components/client-carousel2";
import PageHeader from "@/components/page-header";
import FeatureOne from "@/components/featured-one";
import FeaturedTwo from "@/components/featured-two";
import ServiceTabOne from "@/components/service-tab-1";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";

const Services = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Servicios">
        <HeaderOne />
        <PageHeader title="Servicios" name="Servicios" />
        <ServiceTabOne />
        <FeaturedTwo />
        <ClientCarousel2 />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Services;
