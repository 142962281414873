import React from "react";
import featureHand from "@/images/unique-feature-hand.png";
import { FeatureTwoData } from "@/data";
import { Col, Row } from "react-bootstrap";
import { Link } from "gatsby";

const FeaturedTwo = () => {
  const { sectionContent, posts } = FeatureTwoData;
  const { title, subText, content } = sectionContent;
  return (
    <section className="service-style-two">
      <div className="overlay"></div>
      <div className="thm-container">
        <Row>
          <Col lg={6}>
            <div className="service-content">
              <div style={{marginBottom: '100px'}} />
              <div className="sec-title">
                <h2>{title}</h2>
              </div>
            </div>
          </Col>
          
        </Row>
      </div>
    </section>
  );
};

export default FeaturedTwo;
